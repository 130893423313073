@import '../../css/mixins.scss';

.positionContainer {
  margin-top: 164 / 880 * 100vh;
}

.sectionText {
  max-width: 47.5rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.2rem;
  text-align: center;

  @include tabletPortraitAndUp {
    margin-bottom: 3.2rem;
  }
}

.textLink {
  text-decoration: underline;
  transition: opacity 150ms ease-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.sectionButtons {
  max-width: 35rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;

  @include tabletPortraitAndUp {
    margin-top: 5.8rem;
    margin-bottom: 8.4rem;
  }
}

.sectionReturn {
  width: 100%;
  text-align: center;
  span {
    opacity: 0.65;
  }
}

.sectionEmail {
  max-width: 58rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.2rem;
}
