@font-face {
  font-family: 'LL Circular';
  src: url('../fonts/lineto-circular-black.eot');
  src: url('../fonts/lineto-circular-black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lineto-circular-black.woff2') format('woff2'),
    url('../fonts/lineto-circular-black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'LL Circular';
  src: url('../fonts/lineto-circular-blackItalic.eot');
  src: url('../fonts/lineto-circular-blackItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lineto-circular-blackitalic.woff2') format('woff2'),
    url('../fonts/lineto-circular-blackItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'LL Circular';
  src: url('../fonts/lineto-circular-bold.eot');
  src: url('../fonts/lineto-circular-bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lineto-circular-bold.woff2') format('woff2'),
    url('../fonts/lineto-circular-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'LL Circular';
  src: url('../fonts/lineto-circular-boldItalic.eot');
  src: url('../fonts/lineto-circular-boldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lineto-circular-bolditalic.woff2') format('woff2'),
    url('../fonts/lineto-circular-boldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'LL Circular';
  src: url('../fonts/lineto-circular-book.eot');
  src: url('../fonts/lineto-circular-book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lineto-circular-book.woff2') format('woff2'),
    url('../fonts/lineto-circular-book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'LL Circular';
  src: url('../fonts/lineto-circular-bookItalic.eot');
  src: url('../fonts/lineto-circular-bookItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lineto-circular-bookitalic.woff2') format('woff2'),
    url('../fonts/lineto-circular-bookItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'LL Circular';
  src: url('../fonts/lineto-circular-medium.eot');
  src: url('../fonts/lineto-circular-medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lineto-circular-medium.woff2') format('woff2'),
    url('../fonts/lineto-circular-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'LL Circular';
  src: url('../fonts/lineto-circular-mediumItalic.eot');
  src: url('../fonts/lineto-circular-mediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lineto-circular-mediumitalic.woff2') format('woff2'),
    url('../fonts/lineto-circular-mediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
